import Logo from "../assets/logo.png";

const Header = () => {
  return (
    <header className="sticky-nav">
      <div className="header-container">
        <h1>SK EXIM INDIA </h1>
        <img className="logo" src={Logo} alt="sk-logo" />
      </div>
      <p>
        We deal in High quality Industrial &amp; Automotive lubricants and
        European Car Service parts Specialist Which Includes BMW, Mercedes Benz,
        Volvo, JLR, VW, Skoda, Audi
      </p>
      <p>
        Authorized Importers &amp; Distributors of COMLINE AUTO PARTS -UK,
        MILLERS OILS-UK, MANN Filters, WIX Filters, MAHLE, MEYLE
      </p>
    </header>
  );
};

export default Header;
