import React from "react";

const Footer = () => {
  return (
    <div className="copy_rights">
      <span>© SK EXIM INDIA</span>
      <span>ALL RIGHTS RESERVED</span>
    </div>
  );
};

export default Footer;
