import React from "react";

import comline from "../assets/Comline.png";
import Meyle from "../assets/MEYLE_Logo_4C_W_aufBlau_0.jpg";
import Millers from "../assets/Millers-Oils-Logo.png";
import Mann from "../assets/MANN.png";
import WixWide from "../assets/WIX_HD_wide.png";
const Main = () => {
  return (
    <section className="section-main">
      <div className="container comline grid grid--2-cols">
        <div>
          <h2>COMLINE AUTO PARTS UK</h2>
          <p>
            Established in 1991, Comline is an independent British brand that is
            currently one of the fastest growing in the
            European automotive industry.
          </p>
          <p>
            Comline Auto Parts provides automotive replacement parts like
            filters, clutch, steering&amp; suspension parts, Brake Pads and
            Brake Disc, Lubricants and Bearings.
          </p>
          <p>
            SK EXIM INDIA is an Authorized Importer and Distributor for COMLINE
            AUTO PARTS in INDIA.
          </p>
        </div>
        <div className="img-box">
          <img src={comline} alt="comline" />
        </div>

        <div className="img-box">
          <img src={Millers} alt="Millers" className="miller" />
        </div>
        <div>
          <h2>MILLERS OILS</h2>
          <p>
            Millers Oils is a leading manufacturer and independent blender of
            oils and lubricants, operating since 1887 in Brighouse, West
            Yorkshire. Millers Oils are a highly innovative independent blender
            of market leading advanced oils and fuel treatments with a history
            of providing creative lubrication solutions for some of the world’s
            best-known brands. Their product portfolios include Industrial
            Automotive and Motorsport applications.
          </p>
          <p>
            SK EXIM INDIA is an Exclusive Authorized Distributor for MILLERS
            OILS in INDIA.
          </p>
        </div>

        <div>
          <h2>MANN FILTERS</h2>
          <p>
            With over 70 years of experience in filtration, MANN-FILTER is known
            around the world for its OE competence. All products are produced to
            current international OEM production and testing standards. At the
            same time there is only one global quality standard: OE quality.
          </p>
          <p>SK EXIM INDIA is an Exclusive Authorized Distributor for India.</p>
        </div>
        <div className="img-box">
          <img src={Mann} alt="Mann" />
        </div>

        <div className="img-box">
          <img src={WixWide} alt="WixWide" />
        </div>
        <div>
          <h2>WIX FILTERS</h2>
          <p>
            WIX Filters, a global manufacturer of filtration products, is
            celebrating 80 years of innovation, expert engineering and
            commitment to high quality filtration.
          </p>
          <p>
            SK EXIM INDIA is an Authorized distributor for WIX Filters for their
            European passenger car applications.
          </p>
        </div>

        <div>
          <h2>MEYLE</h2>
          <p>
            MEYLE offers precise solutions and parts for every situation and
            every driver, from competent workshop employee and ambitious rally
            driver to classic car enthusiast and every driver around the world
            who needs to be able to rely on their own car.  With MEYLE ORIGINAL
            products, drivers are always on the safe side when it comes to
            reliability and comfort.
          </p>
          <p>
            Whether steering and suspension components, rubber-to-metal parts,
            brakes, damping components, cooling systems, filters, drive train
            components, auto electronics or sensing technology MEYLE is
            synonymous with top-grade quality-tested spare parts!
          </p>
        </div>
        <div className="meyle-container img-box">
          <img src={Meyle} alt="Meyle" className="meyle" />
        </div>
      </div>
    </section>
  );
};

export default Main;
