import React from "react";
import Logo from "../assets/logo.png";

const Contact = () => {
  return (
    <div className="section-footer ">
      <div className="footer-logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="address">
        <h3>Address</h3>
        <ul>
          <li>SK EXIM INDIA</li>
          <li>85, RAMACHANDRA LAYOUT,</li>
          <li>RAJA ANNAMALAI ROAD,</li>
          <li>SAIBABA COLONY</li>
          <li>COIMBATORE 641011</li>
          <li>TAMILNADU</li>
        </ul>
      </div>
      <div className="contact">
        <h3>Contact Us</h3>
        <span>+91 888 333 2093</span>
        <button
          onClick={() => {
            window.open(`https://wa.me/${+918883332093}`, "_blank");
          }}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Contact;
