import Header from "./Header";
import Main from "./Main";
import Contact from "./Contact";
import "./Index.css";

import Whatsapp from "../assets/whatsapp.png";
import Footer from "./Footer";
const Index = () => {
  return (
    <div>
      <Header />
      <div className="whatsapp">
        <img
          src={Whatsapp}
          alt="Whatsapp"
          onClick={() => {
            window.open(`https://wa.me/${+918883332093}`, "_blank");
          }}
        />
      </div>
      <main>
        <Main />
        <section className="contact">
          <Contact />
        </section>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Index;
